function csvParser(bufferString) {
  const arr = bufferString.split("\n");
  const headers = arr.shift().split(",");
  const jsonObj = [];
  arr.forEach((elem) => {
    const data = elem.split(",");
    const obj = {};
    headers.forEach((header, index) => {
      obj[header.trim()] = data[index].trim();
    });
    jsonObj.push(obj);
  });
  return jsonObj;
}

export default csvParser;
