import React, { useState, useEffect } from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import { StaticImage, GatsbyImage } from "gatsby-plugin-image";
import csvParser from "../utils/csv-parser";
// import styles from '../scss/header.module.scss';

const Header = () => {
  const [metrics, setMetrics] = useState([]);
  const baseUrl = 
  "https://docs.google.com/spreadsheets/d/e/2PACX-1vSAvfWhvZ6Jn0hoPgxO_HiHwvnGX9ItyeMFOpmtzNfa7xvkKWhkdYNIaF7TboAAIoaFGIwk7Ck9UQjA/";
  const spreadsheetid = "1tJI8OIV2F3BXFkniSODtyr3smj3SS2zQGc5Q-x5N8kI";
  const range = "Total!A2:C3";
  const key = "AIzaSyAJ9pMGaHcmOiNeHEXQLGCiJcr5k3TV4F8";
  const url = `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetid}/values/${range}?key=${key}`;

  function processData(arr) {
    setMetrics(arr);
  }

  // useEffect(() => {
  //   // get data from Google Sheets
  //   fetch(url)
  //     .then(response => console.log(response))
  // }, []);

  useEffect(() => {
    // get data from Google Sheets
    fetch(baseUrl + "pub?gid=405192847&single=true&output=csv")
      .then(response => response.text()) // parse text from response
      .then(resultData => {
        processData(csvParser(resultData));
      });
  }, []);

  //   const images = useStaticQuery(graphql`{
  //   logoVlabs: file(
  //     relativePath: {eq: "header/logo-vlabs.png"}
  //     sourceInstanceName: {eq: "images"}
  //   ) {
  //     childImageSharp {
  //       gatsbyImageData(height: 56, placeholder: TRACED_SVG, layout: FIXED)
  //     }
  //   }
  //   logoVlabsMobile: file(
  //     relativePath: {eq: "header/logo-vlabs.png"}
  //     sourceInstanceName: {eq: "images"}
  //   ) {
  //     childImageSharp {
  //       gatsbyImageData(height: 40, placeholder: TRACED_SVG, layout: FIXED)
  //     }
  //   }
  //   logoIiith: file(
  //     relativePath: {eq: "header/logo-iiith.png"}
  //     sourceInstanceName: {eq: "images"}
  //   ) {
  //     childImageSharp {
  //       gatsbyImageData(height: 56, layout: FIXED)
  //     }
  //   }
  //   logoIiithMobile: file(
  //     relativePath: {eq: "header/logo-iiith.png"}
  //     sourceInstanceName: {eq: "images"}
  //   ) {
  //     childImageSharp {
  //       gatsbyImageData(height: 40, layout: FIXED)
  //     }
  //   }
  // }
  // `);
  return (
    <nav
      className='navbar box is-fixed-top p-0'
      role='navigation'
      aria-label='main navigation'
    >
      <input
        type='checkbox'
        id='toggler'
        role='button'
        className='toggler'
        aria-label='menu'
        aria-expanded='false'
        data-target='navbarBasicExample'
      />
      <div className='navbar-brand'>
        <a href='http://www.vlab.co.in/' className='navbar-item'>
          <StaticImage
            src='../images/header/logo-vlabs.png'
            alt='Virtual Labs'
            height={56}
            placeholder='TRACED_SVG'
            layout='FIXED'
          />
        </a>
        {/* <a href="http://www.vlab.co.in/" className="navbar-item">
          <GatsbyImage
            image={[
              {
                ...images.logoVlabs.childImageSharp.gatsbyImageData,
                media: `(min-width: 1024px)`,
              },
              {
                ...images.logoVlabsMobile.childImageSharp.gatsbyImageData,
                media: `(max-width: 1023px)`,
              },
            ]} />
        </a> */}
        <hr className='navbar-divider'></hr>
        <a href='https://www.iiit.ac.in/' className='navbar-item'>
          <StaticImage
            src='../images/header/logo-iiith.png'
            alt='IIIT'
            height={56}
            placeholder='TRACED_SVG'
            layout='FIXED'
          />
        </a>
        {/* <a href="https://www.iiit.ac.in/" className="navbar-item">
          <GatsbyImage
            image={[
              {
                ...images.logoIiith.childImageSharp.gatsbyImageData,
                media: `(min-width: 1024px)`,
              },
              {
                ...images.logoIiithMobile.childImageSharp.gatsbyImageData,
                media: `(max-width: 1023px)`,
              },
            ]} />
        </a> */}
        <label
          htmlFor='toggler'
          className='navbar-burger burger'
          style={{ position: "absolute", right: "10px", top: "10px" }}
        >
          <span aria-hidden='true'></span>
          <span aria-hidden='true'></span>
          <span aria-hidden='true'></span>
        </label>
      </div>
      {metrics.length > 0 && (
        <div id='metrics' className='metrics'>
          <Link to='/analytics' className='metrics-link'>
            <div
              id='pageviews'
              className='metrics-box mr-0 has-background-explore'
            >
              <p>{metrics[1].Metric}</p>
              <p>{metrics[1]["Short Form"]}</p>
            </div>
          </Link>
          <Link to='/analytics' className='metrics-link'>
            <div id='users' className='metrics-box has-background-discover'>
              <p>{metrics[0].Metric}</p>
              <p>{metrics[0]["Short Form"]}</p>
            </div>
          </Link>
        </div>
      )}
      <div id='navbarBasicExample' className='navbar-menu'>
        <div className='navbar-end'>
          <Link to='/' className='navbar-item'>
            Home
          </Link>
          <Link to='/#about' className='navbar-item'>
            About
          </Link>
          <Link to='/#labs' className='navbar-item'>
            Labs
          </Link>
          <Link to='/#activities' className='navbar-item'>
            Activities
          </Link>
          <Link to='/#services' className='navbar-item'>
            Services
          </Link>
          {/* <Link to='/#team' className='navbar-item'>
            Team
          </Link> */}
          <Link to='/#faq' className='navbar-item'>
            FAQ
          </Link>
          <Link to='/#contact' className='navbar-item'>
            Contact
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default Header;
