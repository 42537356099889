import React from 'react';
// import styles from '../scss/footer.module.scss';
import { StaticImage } from "gatsby-plugin-image";

const Footer = () =>
<div id="contact" style={{paddingTop:"3vw"}}>
  <center>
  <div className="task-container columns is-multiline is-horizontal-center" style={{backgroundColor:"#000",paddingBottom:"10px",paddingTop:"10px"}}>
    <div className="card column is-two-half " style={{flex:"auto"}}  id="faq1" style={{borderRadius:"2px",backgroundColor:"#111",paddingTop:"3px"}} >
      <div className="columns is-vtop">

        <div className="column">
          <p className="subtitle is-5" style={{fontSize:"16px",color:"#fff",fontFamily:"'Open Sans', sans-serif"}}>Address</p>
          <hr style={{backgroundColor:"#0080ff"}}/>
          <p style={{fontSize:"17.5px",color:"#fff",fontFamily:"'Open Sans', sans-serif"}}>
            Engineering and Architecture Division :<br/>
            Room No:B6-204,<br/>
            Vindhya C6, VLEAD, IIIT-H, Gachibowli,<br/>
            Hyderabad - 500032
          </p>
        </div>

        <div className="column">
          <p className="subtitle is-5" style={{fontSize:"16px",color:"#fff",fontFamily:"'Open Sans', sans-serif"}}>Contact</p>
          <hr style={{backgroundColor:"#0080ff"}}/>
          <p style={{fontSize:"17.5px",color:"#fff",fontFamily:"'Open Sans', sans-serif"}}>
          {/* <strong style={{color:"#fff"}}>Phone:</strong> <br/> */}
                  General Information : <a href="tel:011-64674687">011-64674687 </a><br/>
                  Development/Outreach : <a href="tel:+91-9177792945">+91-9177792945</a><br/>
                  <strong style={{color:"#fff"}}>Email:</strong> 
                  &nbsp;<a href="mailto:support@vlabs.ac.in?subject=Virtual Labs">support@vlabs.ac.in</a>
                  <br/>
          </p>
        </div>

        <div className="column">
          <p className="subtitle is-5" style={{fontSize:"16px",color:"#fff",fontFamily:"'Open Sans', sans-serif"}}>Follow Us</p>
          <hr style={{backgroundColor:"#0080ff"}}/>
          <div className="social-links">
            <a href="https://www.facebook.com/VLabsIITDelhi/" style={{outline:"none"}}>
              <StaticImage src='../images/footer/social-network-logos/facebook.png' style={{height:"50px",width:"50px",padding:"3px"}} alt="Facebook"/>
            </a>
            <a href="https://twitter.com/TheVirtualLabs" style={{outline:"none"}}>
              <StaticImage src='../images/footer/social-network-logos/twitter.png' style={{height:"50px",width:"50px",padding:"3px"}} alt="Twitter"/>
            </a>
            <a href="https://www.youtube.com/watch?v=asxRaOgk6a0" style={{outline:"none"}}>
              <StaticImage src='../images/footer/social-network-logos/youtube.png' style={{height:"50px",width:"50px",padding:"3px"}} alt="Youtube"/>
            </a>
            <a href="https://www.linkedin.com/in/virtual-labs-008ba9136" style={{outline:"none"}}>
              <StaticImage src='../images/footer/social-network-logos/linkedin.png' style={{height:"50px",width:"50px",padding:"3px"}} alt="LinkedIn"/>
            </a>       
          </div>
        </div>
      </div>
    </div>
  </div>
  </center>
</div>

export default Footer;