import React from 'react';

import Header from '../components/header';
import Footer from '../components/footer';
import Chatbot from '../components/chatbot';

import '../scss/layout.scss';

const Layout = (props) =>
    <>
        <Header />
        {props.children}
        <Chatbot />
        <Footer />
    </>

export default Layout;
