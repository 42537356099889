import React from 'react';
import { Helmet } from 'react-helmet';

const Chatbot = () =>
    <React.Fragment>
        <Helmet>
            <script src="https://www.gstatic.com/dialogflow-console/fast/messenger/bootstrap.js?v=1"></script>
        </Helmet>
        <df-messenger
            intent="WELCOME"
            chat-title="Vidya"
            agent-id="516a514e-382a-4364-9499-94d4994a4835"
            language-code="en"
        >
        </df-messenger>
    </React.Fragment>;

export default Chatbot;